<template>
  <div id="app-question-add" class="app-add">
    <el-table
        :data="records"
        :default-sort = "{prop: 'createdOn', order: 'ascending'}"
        v-show="tableShow"
        style="width: 100%" >
      <el-table-column   label="排名" align="center">
        <template slot-scope="scope">{{scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="studentId" label="学员ID" align="center"></el-table-column>
      <el-table-column prop="nickname" label="昵称" align="center"></el-table-column>
      <el-table-column prop="username" label="手机号" align="center" width="150"></el-table-column>
      <el-table-column prop="questionScore" label="答题得分" align="center"></el-table-column>
      <el-table-column prop="lessonScore" label="听课得分" align="center"></el-table-column>
      <el-table-column prop="score" label="综合得分" align="center"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import {request, UrlEnum,TaskTypeEnum,TaskCategoryEnum} from "../../../public/js/common-vue";

export default {
  name:"TaskDetail",
  data() {
    return {
      records:[],
      courseId:0,
      tableShow:false
    }
  },
  methods: {
    initData() {
      var url = UrlEnum.COURSE_TASK_REPORT + "?courseId=" + this.courseId;
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.total = res.data.total;
        this.records = res.data.list;
        if(this.records.length>0){
          for(let i=0;i<this.records.length;i++){
            this.records[i].rank = (i+1)*this.page;
          }
        }
        this.tableShow=true;
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    }
  },
  mounted() {
    this.courseId = request("courseId");
    this.initData();
  }
}
</script>

<style scoped>
@import "../../../static/css/add.css";
</style>
